import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import NewTable from "../../../../components/Admin/NewTable";
import InputText from "../../../../components/Inputs/InputText";
import {apiList, apiListProducts, apiListStatus} from "../../../../services/admin/container";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import moment from "moment";
import {Link} from "react-router-dom";
import {Button, Chip, IconButton} from "@mui/material";
import Stack from "@mui/material/Stack";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function User() {
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(null)
    const [msgError, setMsgError] = useState([])


    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getContainer()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getContainer = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.idCompany) { params.idCompany = state.idCompany }
        if (state.idWarehouse) { params.idWarehouse = state.idWarehouse }
        if (state.statusId) { params.statusId = state.statusId }
        if (state.idpInvoice) { params.idpInvoice = state.idpInvoice }
        if (state.containerReference) { params.containerReference = state.containerReference }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            setRowCount(resp.data.total)
            let temp = resp.data.data?.map((doc, key) => {
                return {
                    ...doc,
                    idContainer: doc.id,
                    id: key,
                    dateEtd: moment.unix(doc?.dateEtd).format("Y/M/D"),
                    dateEta: doc?.dateEtaWhse ? moment.unix(doc?.dateEtaWhse).format("Y/M/D") : "-",
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <h1>Display Container</h1>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default" style={{paddingBottom: 10}}>
                        <Filters
                            state={state}
                            setState={setState}
                            setMsgError={setMsgError}
                        />
                        <NewTable
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />

                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

function Filters(props) {

    const {state, setState, setMsgError} = props
    const [company, setCompany] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const {logout} = useAuth()

    useEffect(() => {
        getCompany()
        getStatus()
        getWarehouse()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }

    const getStatus = async () => {
        apiListStatus().then(resp => {
            setListStatus(resp.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    const getWarehouse = async () => {
        const response = await listApi(logout, "warehouse")
        const temp = response.data.map((doc) => {
            return {
                id: doc.id,
                name: doc.name
            }
        })
        setWarehouse(temp)
    }

    return (
        <Grid container spacing={1} style={{padding:'20px'}}>
            <Grid item xs={6}>
                <InputSelect
                    label="Company"
                    name="idCompany"
                    onChange={handleChange}
                    value={state?.idCompany}
                    options={company}
                />
            </Grid>
            <Grid item xs={4}>
                <InputSelect
                    label="Warehouse"
                    name="idWarehouse"
                    required
                    onChange={handleChange}
                    value={state?.idWarehouse}
                    options={warehouse}
                />
            </Grid>
            <Grid item xs={2}>
                <InputSelect
                    label="Status"
                    name="statusId"
                    required
                    onChange={handleChange}
                    value={state?.statusId}
                    options={listStatus}
                />
            </Grid>
            <Grid item xs={6}>
                <InputText
                    label="IDP Invoice"
                    name="idpInvoice"
                    onChange={handleChange}
                    value={state?.idpInvoice}
                />
            </Grid>
            <Grid item xs={4}>
                <InputText
                    label="Container Ref"
                    name="containerReference"
                    onChange={handleChange}
                    value={state?.containerReference}
                />
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={1}>
                <Button variant="text"  onClick={() => setState(initialValues())} style={{float: 'right', height: 35}}>
                    <HighlightOffIcon />
                </Button>
            </Grid>
        </Grid>
    )
}
function tableColumns(props) {
    return [
        {
            field: "company",
            headerName: "COMPANY",
            flex: 2,
            sortable: true,
            renderCell: (params) => {
                if(params.company){
                    let company = params.company.split(",")
                    return (
                        <>
                            <Stack direction="row" spacing={1}>
                                {company.map((item, key) => {
                                    return <Chip key={key} label={item} size="small"/>
                                })}
                            </Stack>
                        </>
                    )
                }
            },
        },
        {
            field: "warehouseName",
            headerName: "WAREHOUSE",
            flex: 1,
            sortable: true
        },
        {
            field: "idpInvoice",
            headerName: "IDP INVOICE",
            flex: 1,
            sortable: true
        },
        {
            field: "containerReference",
            headerName: "CONTAINER REF",
            flex: 1,
            sortable: true
        },
        {
            field: "dateEtd",
            headerName: "ETD",
            flex: 1,
            sortable: true
        },
        {
            field: "dateEta",
            headerName: "ETA",
            flex: 1,
            sortable: true
        },
        {
            field: "statusName",
            headerName: "TOTAL",
            flex: 1,
            sortable: true
        },
        {
            field: "statusName",
            headerName: "STATUS",
            flex: 1,
            sortable: true
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/containers/edit/${params.idContainer}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        idCompany: "",
        warehouse: "",
        statusId: "",
        idpInvoice: "",
        containerReference: "",
    }
}
