import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants";
import { authFetch } from "../../utils/fetch";

export async function registerApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/auth/register`;
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function createApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/create`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function loginApi(formData) {
    try {
        const url = `${BASE_PATH}/api/login-check`;
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function showUsersApi(logout) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function gerUserApi(logout, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/${id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateUserApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/update`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function requestResetPasswordApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/auth/request-reset-password`;
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function resetPasswordApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/auth/reset-password`;
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function importUsersApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/import`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            constentType: "multipart/form-data",
            body: data,
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function exportUsersApi(logout) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/export`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
           
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {

        return null;
    }
}
