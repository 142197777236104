import React, {useEffect, useMemo, useState} from "react";
import {showUsersApi, importUsersApi, exportUsersApi} from "../../../../services/admin/user";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import moment from "moment";
import { TextField } from "@material-ui/core";
import MsgSuccess from "../../../../components/Admin/MsgSuccess";
import MsgError from "../../../../components/Admin/MsgError";
import Loading from "../../../../components/User/Loading";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Management() {
    const { logout } = useAuth();
    const [users, setUsers] = useState(null);
    const [file, setFile] = useState(null)
    const [msgError, setMsgError] = useState(null)
    const [msgSuccess, setMsgSuccess] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingImport, setLoadingImport] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "company",
                Filter: SelectColumnFilter,
            },
            {
                Header: "ZONE",
                accessor: "region",
                Filter: SelectColumnFilter,
            },
            {
                Header: "REGION",
                accessor: "subregion",
                Filter: SelectColumnFilter,
            },
            {
                Header: "COUNTRY",
                accessor: "country",
                Filter: SelectColumnFilter,
            },
            {
                Header: "STORE",
                accessor: "location",
                disableFilters: true,
            },
            {
                Header: "NAME",
                accessor: "name",
                disableFilters: true,
            },
            {
                Header: "EMAIL",
                accessor: "email",
            },
            {
                Header: "USER ROLE",
                accessor: "role",
                Filter: SelectColumnFilter,
            },
            {
                Header: "ACCESS",
                accessor: "access",
                Filter: SelectColumnFilter
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true,
            },
        ],
        []
    );

    useEffect(() => {
        getUsers()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getUsers = async () => {
        setLoading(true)
            const response = await showUsersApi(logout);
            if (response.status) {

                const temp = response.data.map((doc) => {
                    let href = `/admin/users/edit/${doc.id}`;

                    let role = "";
                    switch (doc.idUserRole.key) {
                        case "ROLE_ADMIN":
                            role = "Admin";
                            break;
                        case "ROLE_REGION":
                            role = "Region";
                            break;
                        case "ROLE_MULTI_STORE":
                            role = "Multi-Store";
                            break;
                        case "ROLE_STOREG":
                            role = "Store";
                            break;
                        case "ROLE_COMPANY_MANAGER":
                            role = "Company Manager";
                            break;
                        case "ROLE_REGION_MANAGER":
                            role = "Approver/Region";
                            break;
                        default:
                            role = "No Role Available";
                            break;
                    }

                    let access = "No";
                    if (doc.isActive) {
                        access = "Yes";
                    }

                    return {
                        id: doc.id,
                        role: role,
                        access: access,
                        active: doc.lastActivity ? moment.unix(doc.lastActivity).format("D/M/Y") : "",
                        company: doc.idCompany?.name,
                        country: doc.idCountry?.name,
                        region: doc.idRegion?.name,
                        subregion: doc.idSubregion?.name,
                        location: doc.locations?.toString(),
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                        ...doc,
                    };
                });
                setUsers(temp);
                setLoading(false)
            } else {
                setLoading(false)
            }
    }

    const handleChangeFile = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files)
        } else {
            setFile(null)
        }
    }

    const downloadTemplate = () => {
        window.open("/import_users.xlsx", '_blank');
    }

    const importUsers = async () => {
        setMsgSuccess([])
        setMsgError([])
        setLoadingImport(true)

        let formData = new FormData()

        formData.append('excelFile', file[0])

       const response = await importUsersApi(logout, formData)

        if (response.status) {
            setLoadingImport(false)
            setMsgSuccess([response.data])
            getUsers()
        } else {
            setLoadingImport(false)
            setMsgError(response.errors)
        }

    }

    const exportUsers = async () => {
        setLoadingExport(true)
        const response = await exportUsersApi(logout);

        if (response.status) {
            setLoadingExport(false)
            window.open(response.data, '_blank');
        }
        setLoadingExport(false)
    }

    if (!users) return "";

    return (
        <>
            <Grid container spacing={3} justifyContent={"space-between"}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Users</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={2} lg={2} style={{textAlign: "right"}}>
                            <InputButtonLoading
                                    onClick={exportUsers}
                                    disabled={loading}
                                    label={'EXPORT'}
                                    loading={loadingExport}
                                    style={{width:"100%", margin: "20px 0px"}}
                                />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} style={{textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{margin: "20px 0px"}}
                                href="/admin/users/create"
                            >
                                + add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{marginTop:'0', marginBottom:'0px'}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <div style={{ marginRight: "20px" }}>
                                <TextField
                                    variant="outlined"
                                    label="Import users"
                                    name="excelFile"
                                    size="small"
                                    type="file"
                                    onChange={handleChangeFile}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ marginRight: "20px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={file === null}
                                    onClick={importUsers}
                                    fullWidth
                                >
                                    Import
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={downloadTemplate}
                                fullWidth
                            >
                                Download template
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MsgSuccess msg={msgSuccess}/>
                    <MsgError msg={msgError}/>
                    <Loading show={loadingImport} title=""/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Loading show={loading} title=""/>
                    { (users && !loading) && (
                    <Box border={1} className="box-default">
                        <TableFilterBase
                            columns={columns}
                            data={users}
                            selectedRows={users}
                            onSelectedRowsChange={setUsers}
                            search={false}
                            selectable={false}
                        />
                    </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
