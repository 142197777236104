import * as React from 'react';
import {createTheme, styled, ThemeProvider} from '@mui/material/styles';
import "./style.css";
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import {Box, Container, Grid, IconButton, Toolbar, Typography, useMediaQuery, useTheme, Divider} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuOne from "../../../../components/Admin/Layout/menuOne";
import MenuTwo from "../../../../components/Admin/Layout/menuTwo";
import MenuAccount from "../../../../components/Admin/Layout/MenuAccount";
import logo from "../../../../assets/images/logo-small.svg";
import Maintenance from "./Maintenance";
import {getToken} from "../../../../services/auth/token";
import useAuth from "../../../../hooks/useAuth";
import {useEffect} from "react";
import {checkAuthDate} from "../../../../services/auth/authKey";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1, transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
    }), ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    '& .MuiDrawer-paper': {
        position: 'relative', whiteSpace: 'nowrap', width: drawerWidth, transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.enteringScreen,
        }), boxSizing: 'border-box', ...(!open && {
            overflowX: 'hidden', transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp, duration: theme.transitions.duration.leavingScreen,
            }), width: 70, [theme.breakpoints.up('sm')]: {
                width: 90,
            },
        }),
    },
}),);

const mdTheme = createTheme();

export default function Basic(props) {
    const {menu} = props
    const {auth} = useAuth();
    const {logout} = useAuth()

    const [open, setOpen] = React.useState(true)
    const toggleDrawer = () => {
        setOpen(!open)
    }

    const smUp = useMediaQuery(useTheme().breakpoints.up("md"))

    useEffect(() => {
        checkAuthDate({
            email: auth.email
        }).catch(err => {
            logout()
        })
    }, [])

    return (<ThemeProvider theme={mdTheme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={open} style={{boxShadow: "none", borderBottom: "1px solid #dbdcdb"}}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                            background: 'rgb(0, 0, 0)',
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="default"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px', ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>

                        <Typography
                            component="h1"
                            variant="h6"
                            color="default"
                            noWrap
                            sx={{flexGrow: 1, color: "white"}}
                        >
                            Dashboard
                        </Typography>

                        <MenuAccount/>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1],
                        }}
                    >

                        <img src={logo} style={{width: '60%', margin: 'auto', marginTop: '20px'}} />

                        {!smUp && (
                            <IconButton onClick={toggleDrawer}>
                                <ChevronLeftIcon/>
                            </IconButton>
                        )}

                    </Toolbar>
                    <MenuOne menu={menu}/>
                    <Divider />
                    <MenuTwo menu={menu}/>
                </Drawer>

                <Box
                    component="main"
                    sx={{
                        backgroundColor: '#ffffff',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container sx={{mb: 4, mt: 4}}>

                        <Maintenance />

                        {props.children}
                    </Container>
                </Box>
            </Box>

            <style>
                {`
                    h3.fields-title {
                      padding: 0px 0px 20px 0px;
                      margin: 0px 0px 0px 0px;
                      border-bottom: 1px solid #e8ebef;
                      letter-spacing: 1px;
                      font-size: 23px;
                    }
            
                    h3.fields-title-1 {
                      padding: 30px 0px 20px 0px;
                      margin: 0px 0px 0px 0px;
                      border-bottom: 1px solid #e8ebef;
                      border-top: 1px solid #e8ebef;
                      letter-spacing: 1px;
                      font-size: 23px;
                    }
                `}
            </style>

        </ThemeProvider>
    )
}
