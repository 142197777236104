import useAuth from "../../../hooks/useAuth"
import React, {useEffect, useState} from "react"
import {listApi} from "../../../services/user/request"
import Grid from "@material-ui/core/Grid";
import {getCatalogByCompanyApi} from "../../../services/user/catalog";
import InputSelect from "../../../components/Inputs/InputSelect";
import SelectStore from "../../../components/User/SelectStore";

export default function HeaderOrder(props) {

    const {auth} = useAuth()

    const [cartZoneDetails, setCartZoneDetails] = useState({
        region: "",
        subregion: "",
        country: "",
        location: ""
    })

    const {
        setRegion,
        setSubregion,
        country,
        setCountry,
        regionSelected,
        productCategorySelected,
        setLocationSelected,
        setProductCategorySelected,
        productCategory,
        setProductCategory,
        setProducts,
        setLocation,
        setLocationRefPo,
        setLocationRefPoTov,
    } = props
    const {logout} = useAuth()

    const [filter, setFilter] = useState({
        productCatalog: "",
        productCategory: "",
        idLocation: ""
    })

    useEffect(() => {
        if (filter.idLocation) {
            setLocation(filter.idLocation)
        }
    }, [filter])

    const [productCatalog, setProductCatalog] = useState([])
    const [productCatalogSelected, setProductCatalogSelected] = useState([])

    const searchProductCategory = async () => {
        const response = await listApi(logout, "product-category");
        if (response.status) {
            setProductCategory(response.data)
        }
    }
    const searchProductCatalog = async () => {
        const response = await getCatalogByCompanyApi(logout, auth.companyId);
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setProductCatalog(temp)
        }
    }

    useEffect(() => {
        (async () => {
            await searchProductCategory()
            await searchProductCatalog()

            filter["idLocation"] = auth?.location?.id
            setFilter({...filter})
        })()
    }, [auth])

    useEffect(() => {
        searchProduct()
    }, [filter])

    const searchProduct = () => {
        if (filter?.idLocation) {
            props.listProducts(filter?.idLocation, filter?.productCategory, filter?.productCatalog)
        }
    }

    const selectLocation = (idLocation) => {
        setProductCategorySelected(null)
        if (idLocation) {
            setLocationSelected(idLocation)
            cartZoneDetails["location"] = idLocation
            localStorage.setItem('cart_products', "[]")
            localStorage.setItem('cart_zone_details', JSON.stringify(cartZoneDetails));
            props.listProducts(regionSelected?.id, productCategorySelected?.id, idLocation, country, productCatalogSelected?.id)
        } else {
            localStorage.setItem('cart_products', "[]")
            setProducts([])
            setLocationSelected(null)
        }

    }

    const handleChange = (event) => {
        if (event.target.name === "idLocation") {
            selectLocation(event.target.value?.id)
            setFilter({...filter, idLocation: event.target.value?.id})
            setRegion(event.target.value?.idRegion)
            setSubregion(event.target.value?.idSubregion)
            setCountry(event.target.value?.idCountry)
            setLocationRefPo(event.target.value?.customerRefPo)
            setLocationRefPoTov(event.target.value?.customerRefPoTov)

            localStorage.setItem('cart_zone_details', JSON.stringify({
                region: event.target.value?.idRegion,
                subregion: event.target.value?.idSubregion,
                country: event.target.value?.idCountry,
                location: event.target.value?.id
            }));

        } else {
            filter[event.target.name] = event.target.value
            setFilter({...filter})
        }
    }

    return (
        <>
            <Grid item xs={6} md={12}>
                <Grid container spacing={1}>
                    {!auth?.location?.id && (
                        <Grid item md={4}>
                            <SelectStore
                                onChange={handleChange}
                            />
                        </Grid>
                    )}

                    <Grid item md={auth?.location?.id ? 6 : 4}>
                        <InputSelect
                            label="Catalog"
                            name="productCatalog"
                            onChange={handleChange}
                            value={filter?.productCatalog}
                            disabled={!filter?.idLocation}
                            options={productCatalog}
                        />
                    </Grid>

                    <Grid item md={auth?.location?.id ? 6 : 4}>
                        <InputSelect
                            label="Category"
                            name="productCategory"
                            onChange={handleChange}
                            value={filter?.productCategory}
                            disabled={!filter?.idLocation}
                            options={productCategory}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
